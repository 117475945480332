<template>
    <section id="carHireIframeSection">
      <div
        v-html="stickyBookingBarCarHireIframeObjhtml"
        id="stickyBookingBarCarHireIframe"
        class="carHireIframeSection"
      ></div>
    </section>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: "StickyBookingBarCarHireIframeForm",
    props: ["selectedLanguage", "componentData", "pageInfo"],
    data() {
      return {
        webpage: "",
        stickyBookingBarCarHireIframeObjhtml: "",
      };
    },
    computed: {},
    mounted() {
      this.loadStickyBookingBarCarHireIframeObj();
    },
    methods: {
        loadStickyBookingBarCarHireIframeObj() {
        const apiUrl =
          process.env.VUE_APP_REMOTEBASEURL +
          `/api/CarHireIframeApi/CarHireIframeObj?` +
          `&iataCode=${process.env.VUE_APP_IATACODE}`;
        axios
          .get(apiUrl)
          .then((response) => {
            this.stickyBookingBarCarHireIframeObjhtml = response.data.html;
            if (response.data.script != "") {
              let divScripts = document.getElementById("stickyBookingBarCarHireIframe");
              let newScript = document.createElement("script");
              newScript.src = response.data.script;
              divScripts.appendChild(newScript);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },
  };
  </script>
  
  <style lang="scss">
  .carHireIframeSection {
    height: 330px;
    max-width: 100%;
    width: 100%;
    margin: 25px auto 0;
    border: 0;
  
    @include breakpoint($tablet) {
      height: 500px;
      max-width: 720px;
    }
  
    @include breakpoint($desktop) {
      max-width: 1140px;
      max-height: 145px;
    }

    iframe html body {
        background-color: red !important;
    }
  }
  </style>
  