<template>
  <VueHead v-if="info.fields">
    <!-- Modify page head attributes using https://github.com/vueuse/head -->
    <title>{{ info.fields.metaTitle }}</title>
    <meta name="description" :content="info.fields.metaDescription" />
    <meta name="keywords" :content="info.fields.metaKeywords" />
    <meta name="title" :content="info.fields.metaTitle" />
    <meta
      name="robots"
      :content="info.fields.metaRobots"
      v-if="info.fields.statusCode == 200"
    />
    <meta name="robots" content="noindex" v-else />
    <meta name="prerender-status-code" :content="info.fields.statusCode" />
    <meta
      name="prerender-header"
      :content="'Location: ' + redirectUrlWithBase"
      v-if="info.fields.statusCode == 301"
    />
    <meta
      http-equiv="refresh"
      :content="'0; url=' + info.fields.redirectUrl"
      v-if="info.fields.statusCode == 301"
    />
    <meta
      name="prerender-header"
      :content="'Location: ' + redirectUrlWithBase"
      v-if="info.fields.statusCode == 301"
    />
    <meta
      http-equiv="refresh"
      :content="'0; url=' + info.fields.redirectUrl"
      v-if="info.fields.statusCode == 301"
    />
    <link rel="icon" v-if="loadedIcon" :href="loadedIcon" />
    <link
      rel="apple-touch-icon-precomposed"
      sizes="144x144"
      v-if="loadedIcon"
      :href="loadedIcon"
    />
    <link
      rel="apple-touch-icon-precomposed"
      sizes="114x114"
      v-if="loadedIcon"
      :href="loadedIcon"
    />
    <link
      rel="apple-touch-icon-precomposed"
      sizes="72x72"
      v-if="loadedIcon"
      :href="loadedIcon"
    />
    <link
      rel="apple-touch-icon-precomposed"
      v-if="loadedIcon"
      :href="loadedIcon"
    />
    <!-- CookiePro Cookies Consent Notice start for snnairportgroup.ie -->
    <component
      v-if="info.fields.publicSiteSettings.fields.cookieProConsentId"
      :is="getScriptTag(1)"
      :src="`https://cookie-cdn.cookiepro.com/consent/${info.fields.publicSiteSettings.fields.cookieProConsentId}/OtAutoBlock.js`"
      type="text/javascript"
    ></component>
    <component
      v-if="info.fields.publicSiteSettings.fields.cookieProConsentId"
      :is="getScriptTag(2)"
      src="https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js"
      type="text/javascript"
      charset="UTF-8"
      :data-domain-script="`${info.fields.publicSiteSettings.fields.cookieProConsentId}`"
    ></component>
    <component
      v-if="info.fields.publicSiteSettings.fields.cookieProConsentId"
      :is="getScriptTag(3)"
      type="text/javascript"
    >
      function OptanonWrapper() {}
    </component>
    <!-- CookiePro Cookies Consent Notice end for snnairportgroup.ie -->
  </VueHead>

  <div v-if="info.fields">
    <SiteHeader
      :publicSiteSettings="info.fields.publicSiteSettings"
      :languages="info.fields.languages"
      :selectedLanguage="selectedLanguage"
      v-if="info.fields.showHeader"
      @languageChanged="handleLanguageChange"
    />
    <div v-if="this.$route.path == '/sitemap.html'">
      <SiteMap></SiteMap>
    </div>
    <StickyBookingBar
      v-if="
        !info.fields.showMultipleFormBarOverParkingBar &&
        !info.fields.displayNoneOfThePinnedBookingBars &&
        this.$route.path != '/sitemap.html'
      "
      :settings="info.fields"
      :selectedLanguage="selectedLanguage"
      :heroData="info.fields.heros"
      :publicSiteSettings="info.fields.publicSiteSettings"
    />

    <StickyFormBookingBar
      v-if="
        info.fields.showMultipleFormBarOverParkingBar &&
        !info.fields.displayNoneOfThePinnedBookingBars &&
        this.$route.path != '/sitemap.html'
      "
      :pinnedBookingSettings="info.fields"
      :pinnedBookingSelectedLanguage="selectedLanguage"
      :pinnedBookingPublicSiteSettings="info.fields.publicSiteSettings"
      :pinnedBookingPageInfo="info"
    />

    <!-- custom component to basically wrap up all the hero carousel functionality into one line -->
    <HeroSlider
      :duration="8000"
      :heroCollection="info.fields.heros"
      :heroSiteSettings="info.fields.publicSiteSettings"
      v-if="info.fields.heros && this.$route.path != '/sitemap.html'"
    ></HeroSlider>

    <StickyFormsBookingBarMobile
      v-if="this.$route.path != '/sitemap.html'"
      :pinnedBookingSettings="info.fields"
      :pinnedBookingSelectedLanguage="selectedLanguage"
      :pinnedBookingPublicSiteSettings="info.fields.publicSiteSettings"
      :pinnedBookingPageInfo="info"
      @modal-open="openModal"
    />
    <main id="content" v-if="this.$route.path != '/sitemap.html'">
      <template
        v-for="(component, index) in info.fields.components"
        :key="index"
      >
        <component
          v-if="
            component.system.contentType != 'timelineComponent' ||
            (component.system.contentType == 'timelineComponent' &&
              component.fields.arrivals == true &&
              (this.$route.query.flightDirection == 0 ||
                this.$route.query.flightDirection == null)) ||
            (component.system.contentType == 'timelineComponent' &&
              component.fields.arrivals == false &&
              this.$route.query.flightDirection == 1)
          "
          :is="component.system.contentType"
          :componentData="component"
          :selectedLanguage="selectedLanguage"
          :pageInfo="info"
          @modal-open="openModal"
          :publicSiteSettings="info.fields.publicSiteSettings"
        ></component>
      </template>
    </main>
    <div id="cookiebotscript" v-if="loadCookieBot"></div>
    <SiteFooter
      :publicSiteSettings="info.fields.publicSiteSettings"
      v-if="info.fields.showFooter"
    />
  </div>
  <Modal
    :isOpen="isModalOpened"
    @modal-close="closeModal"
    :modalClass="(currentModal || '').toLowerCase()"
    ><component :is="currentModal" v-bind="modalProps"></component
  ></Modal>
</template>

<script>
// @ is an alias to /src
import SiteFooter from "@/components/Footer.vue";
import SiteHeader from "@/components/Header.vue";
import StickyBookingBar from "@/components/StickyBookingBar.vue";
import StickyFormBookingBar from "@/components/StickyFormsBookingBar.vue";
import StickyFormsBookingBarMobile from "@/components/StickyFormsBookingBarMobile.vue";
import StickySalesBar from "@/components/StickySalesBar.vue";
import HeroSlider from "@/components/HeroSlider.vue";
import Modal from "./Modal.vue";
import axios from "axios";
import { Head as VueHead } from "@vueuse/head";
import { computed } from "vue";
import SiteMap from "./SiteMap.vue";

// Selectable components
import LargeWidthFrame from "@/components/Selectable/LargeWidthFrame.vue";
import PromoBlock from "@/components/Selectable/PromoBlock.vue";
import RichTextBlock from "@/components/Selectable/RichTextBlock.vue";
import RetailerListing from "@/components/Selectable/RetailerListing.vue";
import ThreeColumnImageBlockList from "@/components/Selectable/ThreeColumnImageBlockList.vue";
import ProductList from "@/components/Selectable/ProductList.vue";
import SectionedContentList from "@/components/Selectable/SectionedContentList.vue";
import LiveFlightList from "@/components/Selectable/LiveFlightList.vue";
import LiveFlightSearchBlock from "@/components/Selectable/LiveFlightSearchBlock.vue";
import IconCallToActionList from "@/components/Selectable/IconCallToActionList.vue";
import TwoAreaContentBlock from "@/components/Selectable/TwoAreaContentBlock.vue";
import MailingListBlock from "@/components/Selectable/MailingListBlock.vue";
import DestinationList from "@/components/Selectable/DestinationList.vue";
import GetYourGuideComponent from "@/components/Selectable/GetYourGuideComponent.vue";
import FeaturedDestinationsList from "@/components/Selectable/FeaturedDestinationsList.vue";
import BookTaxi from "@/components/Selectable/BookTaxi.vue";
import CarHireIframeComponent from "@/components/Selectable/CarHireIframeComponent.vue";
import TimelineComponent from "@/components/TimelineComponent.vue";
import HotelBooking from "@/components/Selectable/HotelBooking.vue";
import AirlineListComponent from "@/components/Selectable/AirlineListComponent.vue";
import DestinationSelector from "@/components/Selectable/DestinationSelector.vue";
import HotelIframeComponent from "@/components/Selectable/HotelIframeComponent.vue";
import DistribusionSearchWidget from "@/components/Selectable/DistribusionSearchWidget.vue";
import ManualFlightSchedules from "@/components/Selectable/ManualFlightSchedules.vue";
import SmallArticlesList from "@/components/Selectable/SmallArticlesList.vue";
import LargeArticlesList from "@/components/Selectable/LargeArticlesList.vue";
import BlogArticle from "@/components/Selectable/BlogArticle.vue";

//Modals
import WatchFlightModal from "@/components/Modals/WatchFlight";

//Booking Bar
import StickyBookingParkingWidget from "@/components/StickyBookingBarForms/StickyBookingParkingWidget.vue";
import StickyBookingBarSecurityForm from "@/components/StickyBookingBarForms/StickyBookingBarSecurityForm.vue";
import StickyBookingBarLoungeForm from "@/components/StickyBookingBarForms/StickyBookingBarLoungeForm.vue";
import StickyBookingBarCarForm from "@/components/StickyBookingBarForms/StickyBookingBarCarForm.vue";
import StickyBookingBarBookTaxiForm from "@/components/StickyBookingBarForms/StickyBookingBarBookTaxiForm.vue";
import StickyBookingBarHolidaysForm from "@/components/StickyBookingBarForms/StickyBookingBarHolidaysForm.vue";
import StickyBookingBarHotelsForm from "@/components/StickyBookingBarForms/StickyBookingBarHotelsForm.vue";
import StickyBookingBarCurrencyForm from "@/components/StickyBookingBarForms/StickyBookingBarCurrencyForm.vue";
import StickyBookingBarHotelsIFrame from "@/components/StickyBookingBarForms/StickyBookingBarHotelsIFrame.vue";
import StickyBookingBarFlightsIFrame from "@/components/StickyBookingBarForms/StickyBookingBarFlightsIFrame.vue";
import StickyBookingBarCarHireIframeForm from "@/components/StickyBookingBarForms/StickyBookingBarCarHireIframeForm.vue";

// Default labels
import localLabels from "@/labels.json";

/* eslint-disable vue/no-unused-components */
export default {
  name: "ContentPage",
  components: {
    Modal,
    SiteMap,
    SiteFooter,
    SiteHeader,
    VueHead,
    StickyBookingBar,
    StickyFormBookingBar,
    StickyFormsBookingBarMobile,
    StickySalesBar,
    HeroSlider,
    LargeWidthFrame,
    PromoBlock,
    RichTextBlock,
    RetailerListing,
    ThreeColumnImageBlockList,
    ProductList,
    SectionedContentList,
    LiveFlightList,
    LiveFlightSearchBlock,
    IconCallToActionList,
    TwoAreaContentBlock,
    MailingListBlock,
    DestinationList,
    DestinationSelector,
    GetYourGuideComponent,
    FeaturedDestinationsList,
    HotelBooking,
    CarHireIframeComponent,
    BookTaxi,
    TimelineComponent,
    WatchFlightModal,
    AirlineListComponent,
    StickyBookingParkingWidget,
    StickyBookingBarSecurityForm,
    StickyBookingBarLoungeForm,
    StickyBookingBarCarForm,
    StickyBookingBarBookTaxiForm,
    StickyBookingBarHolidaysForm,
    StickyBookingBarHotelsForm,
    StickyBookingBarHotelsIFrame,
    StickyBookingBarFlightsIFrame,
    StickyBookingBarCarHireIframeForm,
    HotelIframeComponent,
    DistribusionSearchWidget,
    ManualFlightSchedules,
    StickyBookingBarCurrencyForm,
    SmallArticlesList,
    LargeArticlesList,
    BlogArticle,
  },
  data() {
    return {
      loadCookieBot: false,
      info: [],
      labels: [],
      webpage: "",
      selectedLanguage:
        sessionStorage.getItem("language") ||
        process.env.VUE_APP_DEFAULTLANGUAGECODE,
      supportedLanguages: ["en", "de", "nl", "ga"],
      isModalOpened: false,
      currentModal: null,
      modalProps: {},
    };
  },
  watch: {
    // Watch for changes in the script field and reload the script
    "info.fields.cookiebotDeclarationScriptSrc": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadCookieBotScript();
      }
    },
  },
  computed: {
    loadedIcon: function () {
      return this.info.fields.publicSiteSettings.fields.icon
        ? this.info.fields.publicSiteSettings.fields.icon.fields.umbracoFile.src
        : "";
    },
    redirectUrlWithBase: function () {
      if (this.info.fields.redirectUrl) {
        return this.info.fields.redirectUrl.startsWith("http")
          ? this.info.fields.redirectUrl
          : window.location.protocol +
              "//" +
              window.location.host +
              this.info.fields.redirectUrl;
      } else {
        return "";
      }
    },
    currentLanguage() {
      return (
        sessionStorage.getItem("language") ||
        process.env.VUE_APP_DEFAULTLANGUAGECODE
      );
    },
  },
  provide() {
    return {
      publicSiteSettings: computed(() => ({
        ...this.info.fields.publicSiteSettings,
      })),
      labels: computed(() => ({
        // Start with local labels file and override with remote settings if available
        ...this.labels,
      })),
    };
  },
  mounted() {
    this.webpage = this.$route.params.slug
      ? "/" + this.$route.params.slug.replace(/\/?$/, "/")
      : "/"; // if the URL has more on it, use this to extract that

    const languageFromUrl = this.getLanguageFromUrl();
    const defaultLanguage =
      sessionStorage.getItem("language") ||
      process.env.VUE_APP_DEFAULTLANGUAGECODE;
    this.selectedLanguage = languageFromUrl || defaultLanguage;

    this.fetchContentForLanguage(this.selectedLanguage);
  },
  methods: {
    getLanguageFromUrl() {
      const path = window.location.pathname;
      const languageCode = path.split("/")[1];
      if (this.supportedLanguages.includes(languageCode)) {
        if (languageCode == "en") {
          return "en-us";
        }
        return languageCode;
      }
      return null;
    },
    getScriptTag(number) {
      // You can customize the logic here to generate different tags based on the number
      switch (number) {
        case 1:
          return "script";
        case 2:
          return "script";
        case 3:
          return "script";
        default:
          return "script"; // Default case
      }
    },
    handleLanguageChange(newLanguage) {
      this.selectedLanguage = newLanguage;
      sessionStorage.setItem("language", newLanguage); // Set the new language in session storage
      this.fetchContentForLanguage(newLanguage);
    },
    fetchContentForLanguage(language) {
      axios
        .get(
          process.env.VUE_APP_REMOTEBASEURL +
            "/api/headlessapi/getpage?url=" +
            this.webpage +
            "&iataCode=" +
            process.env.VUE_APP_IATACODE +
            "&cultureCode=" +
            language
        )
        .then((response) => {
          this.info = response.data;
          this.loadCookieBotScript();
          // Update or add to local labels with remote data
          this.labels = localLabels;

          const labelKeys = [
            "buttonBook",
            "validationTimeGeneric",
            "validationTimeMinutes",
            "validationTimeHours",
            "validationTimeOrder",
            "labelEntryDate",
            "labelTime",
            "labelExitDate",
            "labelAirline",
            "labelSelectAirline",
            "labelPriceTeaser",
            "labelPersistentParkingFormIntro",
            "labelBookATaxi_PickUpLocation",
            "labelBookATaxi_PickUpDate",
            "labelBookATaxi_PickUpTime",
            "labelBookATaxi_DropOffDate",
            "labelBookATaxi_DropOffTime",
            "labelBookATaxi_Adults",
            "labelBookATaxi_Children",
            "labelBookATaxi_Infants",
            "hotelBookingTitle",
            "hotelBookingDestinationsLabel",
            "hotelBookingNoDestinationsText",
            "hotelBookingCheckInLabel",
            "hotelBookingCheckOutLabel",
            "hotelBookingSubmitButtonText",
            "currencyTitle",
            "currencyFieldOne",
            "currencyFieldTwo",
            "currencyFieldThree",
          ];

          labelKeys.forEach((key) => {
            if (response.data.fields.publicSiteSettings.fields[key]) {
              this.labels[key] =
                response.data.fields.publicSiteSettings.fields[key];
            }
          });
        })
        .catch((error) => {
          console.error("Error fetching content for language:", error);
        });
    },

    loadCookieBotScript() {
      if (this.info.fields.cookiebotDeclarationScriptSrc != undefined && this.info.fields.cooiebotDeclarationScriptSrc != "") {
        this.loadCookieBot = true;
        this.$nextTick(() => {
          const scriptElement = document.createElement("script");
          scriptElement.id = "CookieDeclaration";
          scriptElement.type = "text/javascript";
          scriptElement.async = true;
          scriptElement.src = this.info.fields.cookiebotDeclarationScriptSrc;
          const placeholder = document.getElementById("cookiebotscript");
          placeholder.appendChild(scriptElement);
        });
      }
    },
    openModal(modalToOpen, modalProps) {
      this.isModalOpened = true;
      this.currentModal = modalToOpen;
      if (Object.keys(modalProps).length > 0) {
        this.modalProps = modalProps;
      }
    },
    closeModal() {
      this.isModalOpened = false;
      this.currentModal = null;
    },
  },
};
</script>
